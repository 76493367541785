import glider from 'glider-js';

import { production } from '../utils';
import * as program from './program';

(function () {
    'use strict';

    autoPlayYouTubeModal();
    speakerSlider();

    gravityformHook();

    // Lazy load background images
    var lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-background'));
    if ('IntersectionObserver' in window) {
        let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });

        lazyBackgrounds.forEach(function (lazyBackground) {
            lazyBackgroundObserver.observe(lazyBackground);
        });
    }

    setTimeout(function () {
        loadSecret();
    }, 5000);
})();

function autoPlayYouTubeModal() {
    var triggerOpen = jQuery('body').find('[data-tagVideo]');
    triggerOpen.click(function () {
        var theModal = jQuery(this).data('bs-target'),
            videoSRC = jQuery(this).attr('data-tagVideo'),
            // videoSRCauto = videoSRC + "?autoplay=1";
            videoSRCauto = videoSRC;
        jQuery(theModal + ' iframe').attr('src', videoSRCauto);
        jQuery(theModal + ' button.btn-close').click(function () {
            jQuery(theModal + ' iframe').attr('src', videoSRC);
        });
    });
}

function speakerSlider() {
    if (jQuery('.glider').length > 0) {
        new Glider(document.querySelector('.glider'), {
            slidesToScroll: 1,
            // slidesToShow: 4.5,
            slidesToShow: 'auto',
            itemWidth: 173,
            draggable: false,
            scrollLock: true,
            rewind: true,
            exactWidth: true,
            // dots: '.dots',
            arrows: {
                prev: '#glider-prev',
                next: '#glider-next'
            },
            responsive: [
                {
                    // screens greater than >= 992px
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 'auto',
                        slidesToScroll: 1,
                        itemWidth: 247
                    }
                },
                {
                    // screens greater than >= 1200px
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 'auto',
                        slidesToScroll: 1,
                        itemWidth: 330
                    }
                }
            ]
        });
    }
}

function loadSecret() {
    // var script = document.createElement('script');
    // script.src = '/wp-content/themes/conference-2025/assets/dist/scripts/underscore.js';
    // script.async = true;
    // script.defer = true;
    // document.head.appendChild(script);
}

/*
Gravity Forms
*/
function gravityformHook() {
    const target = '.blk_form .gform_wrapper';

    if (jQuery(target).length > 0) {
        jQuery('.gform_button').removeAttr('onclick');

        jQuery(`${target} form input, ${target} form textarea`).on('focus', function (event) {
            jQuery(this).parents('.gfield').removeClass('invalid');
            jQuery(this).parents('.gfield').find('.field-message').remove();
        });

        jQuery(`${target} form`).on('submit', function (event) {
            console.log(jQuery(this).parents('.blk_form').attr('data-gf-url'));

            const endpointUrl = jQuery(this)
                .parents('.blk_form')
                .attr('data-gf-url')
                .replace('=', '');
            const formId = jQuery(this).parents('.blk_form').attr('data-gf-id');
            const baseUrl = jQuery(this)
                .parents('.blk_form')
                .attr('data-gf-baseurl')
                .replace('=', '');

            !production() && console.log('Endpoint:', endpointUrl);

            event.preventDefault();

            // Get all data
            const data = {};
            const values = {};
            jQuery.each(jQuery(this).serializeArray(), function (i, field) {
                if (field.name.startsWith('input_')) {
                    values[field.name] = field.value;
                } else if (field.name === 'g-recaptcha-response') {
                    values[field.name] = field.value;
                }
            });
            data.baseUrl = baseUrl;
            data.payload = values;

            !production() && console.log('Request:', { data });

            const json = JSON.stringify(data);

            var currentForm = jQuery(this);

            jQuery.ajax({
                method: 'POST',
                url: endpointUrl,
                data: json,
                contentType: 'application/json',
                beforeSend: function () {
                    // Show spinner
                    jQuery(currentForm).find('.gform_button').attr('disabled', 'disabled');
                    jQuery(currentForm).parents('.blk_form').addClass('loading');
                    jQuery(currentForm).parents('.blk_form').find('.form-messages').html('');
                },
                success: function (data) {
                    !production() && console.log('Response:', { data });

                    const result = data;

                    // Successful
                    if (result.status === 'success') {
                        if (formId === '3' || formId === '5') {
                            window.location.href = '/thank-you-sponsors';
                        } else {
                            window.location.href = '/thank-you';
                        }
                        // if (result.confirmation_message) {
                        //     jQuery(currentForm)
                        //         .parents('.blk_form')
                        //         .find('.form-messages')
                        //         .html(result.confirmation_message);
                        //     jQuery(currentForm)[0].reset();
                        //     // jQuery(currentForm).find('.gform_button').removeAttr('disabled');
                        //     jQuery(currentForm).find('.gform_button').css('display', 'none');
                        //     jQuery(currentForm).parents('.blk_form').removeClass('loading');

                        //     setTimeout(function () {
                        //         jQuery(currentForm)
                        //             .parents('.blk_form')
                        //             .find('.form-messages')
                        //             .html('');
                        //     }, 30000);
                        //     return;
                        // }

                        // Errors
                    } else {
                        var messages = jQuery(currentForm)
                            .parents('.blk_form')
                            .find('.form-messages');
                        messages.html(
                            '<div class="error">Your submission contained the following errors: <ul class="errors"></ul></div>'
                        );

                        jQuery.each(result.validation_messages, function (i, e) {
                            !production() && console.log('Error:', { i, e });
                            const currentField = jQuery(currentForm).find(
                                '#field_' + formId + '_' + i
                            );
                            jQuery(currentField).addClass('invalid');
                            messages.find('.errors').append('<li>' + e + '</li>');
                        });

                        jQuery(currentForm).find('.gform_button').removeAttr('disabled');
                        jQuery(currentForm).parents('.blk_form').removeClass('loading');

                        if (grecaptcha !== undefined) {
                            grecaptcha.reset();
                        }
                    }
                },
                error: function (xhr, status, error) {
                    !production() && console.log('Response:', { xhr, status, error });

                    jQuery(currentForm)
                        .parents(target)
                        .find('.form-messages')
                        .html(
                            '<div class="error-message">There was an error sending message. Please try again later.</div>'
                        );
                    jQuery(currentForm).find('.gform_button').attr('disabled', 'false');
                    jQuery(currentForm).find('.gform_button').removeAttr('disabled');
                    jQuery(currentForm).parents('.blk_form').removeClass('loading');

                    setTimeout(function () {
                        jQuery(currentForm).parents(target).find('.form-messages').html('');
                    }, 30000);
                }
            });
        });
    }
}

/*
 * Misc
 */

function scrollToAnchor(id) {
    var loc = jQuery("[id='" + id + "']");
    $('html,body').animate({ scrollTop: loc.offset().top }, 'slow');
}
